@media (max-width: 767px) {
    .teams {
        .UserNumber {
            .box {
                min-width: 100% !important;

            }
        }

        header {
            display: flex !important;
            flex-wrap: wrap !important;

            .team-filter {
                min-width: 100% !important;

            }

            .navLink {
                min-width: 100% !important;
                margin: 12px 0;


            }

        }

        .actions {
            
            i{
                display: none !important;
            }
          

            .delete-user {
                padding: 0px 4px !important;
            }

        }


    }
    .groups{
        .UserNumber {
            .box {
                min-width: 100% !important;

            }
        } 
        header {
            display: flex !important;
            flex-wrap: wrap !important;

            .group-filter {
                min-width: 100% !important;

            }

            .navLink {
                min-width: 100% !important;
                margin: 12px 0;


            }

        }
        .Collapse{
            *{
                font-size: 12px !important;
            }
            i{
                font-size: 12px !important;
            }
            .num{
                width: 20px !important;
                height: 20px !important;
            }
        }

    }
    .all-boxs{
        .boxs{
            padding: 0!important    ;

            min-width: 100% !important;
        }
       
    }
}


@media (min-width: 768px) and (max-width: 991px){ 
    .teams {
        .UserNumber {
            .box {
                min-width: 100% !important;

            }
        }

        header {
            display: flex !important;
            flex-wrap: wrap !important;

            .team-filter {
                min-width: 100% !important;

            }

            .navLink {
                min-width: 100% !important;
                margin: 12px 0;


            }

        }

        .actions {
            
            i{
                display: none !important;
            }
          

            .delete-user {
                padding: 0px 4px !important;
            }

        }


    }
    .groups{
        .UserNumber {
            .box {
                min-width: 100% !important;

            }
        } 
        header {
            display: flex !important;
            flex-wrap: wrap !important;

            .group-filter {
                min-width: 100% !important;

            }

            .navLink {
                min-width: 100% !important;
                margin: 12px 0;


            }

        }
        .Collapse{
            *{
                font-size: 12px !important;
            }
            i{
                font-size: 12px !important;
            }
            .num{
                width: 20px !important;
                height: 20px !important;
            }
        }

    }
    .all-boxs{
        // flex-wrap: nowrap !important;
         .boxs{
             padding: 0!important    ;
             max-width: 44% !important;
             .box-header{
             }
         }
        
     }
}






     @media (min-width: 992px) and (max-width: 1199px) { 
        .UserNumber {
            .box {
                //min-width: 100% !important;
                margin: 0 12px !important;
            }
        }
        .seach-team{
            button{
                font-size: 11px !important;
                padding:12px 8px !important 
            }
        }
        .all-boxs{
            // flex-wrap: nowrap !important;
             .boxs{
                 padding: 0!important    ;
                 max-width: 30% !important;
                 .box-header{
                 }
             }
            
         }

      }

      @media (min-width: 1200px) { 

        .UserNumber {
            .box {
                //min-width: 100% !important;
                margin: 0 12px !important;
            }
        }
        .user-search{
            padding:12px 9px !important ;
        }
        .all-boxs{
           // flex-wrap: nowrap !important;
            .boxs{
                padding: 0!important    ;
                max-width: 22% !important;
                .box-header{
                }
            }
           
        }
       }
       