@media (max-width: 360px) { 
    .orders-list{
        .list_header{
            flex-wrap: wrap !important;
            .navLink{
                width: 100% !important;
            }
        }
    header{
        h3{
            font-size: 12px !important;
        }
        .navLink{
            //background: red !important;
            display: flex !important;
            flex-direction: column !important;

            .tab-link{
                //background:orange !important;
                font-size: 16px !important;
                padding: 3px !important;
                margin-top: 1px !important;
            }

        }
    }

    /**filter**/
    .order-filter,.filter-check,.filter-progress{
        //background: red !important;
        margin: 0 !important;
        padding: 0 !important;
        display: none;
        
        button{
            font-size: 12px !important;
            padding: 10px 15px  !important;
        }
        
    }
    /**filter**/

    Table {
        padding: 0 !important;

        tbody {
            font-size: 10px !important;
        }

        td {
            padding: 0 !important;
            font-size: 9px !important;
            button{
                width: 15px !important;
                font-size: 9px !important;

                i{
                    font-size: 11px !important;
                    display: none !important;
                }
            }
            .submit-operation-order,.submit-traveller-result{
                display: none !important;
            
            }
        }

        th {
            font-size: 9px !important;
            font-weight: 100 !important;
            padding: 0 !important;


        }

    }
    .check-table,.progress-table{
        th,td{
            font-size: 7px !important;
        }
    }
    .Pagination-all{
        display: none !important;
        *{
            padding: 0   !important;
            //width: 100% !important;
        }
        .navigation{
            padding: 2px 20px  !important;
            width: 100% !important;
            .pagination{
                padding: 2px 20px  !important;
                li{
                    padding: 2px 20px  !important;
                    font-size: 12px !important;

                }
            }
        }
    }
}

/**view**/
.order-list-view{
    .instructions-all{
        padding: 0 !important;
        .instructions{
            h5{
                font-size: 10px!important;
            }
            div{
                p{
                    font-size: 9px !important;
                    //font-weight: 300!important;

                }
            }
        }

    }
    
}



.view-result-progress{
    .m-auto{
        max-width: 90% !important;
    }
    .view{
        .selected-field{
           
            padding: 0 !important;
            font-size: 12px !important;
        }
    }
    button{
        padding:3px 5px !important;
    }
}
/**view**/


    }

    @media (min-width:360px) and (max-width:714px) {
        .order-filter,.filter-check,.filter-progress{
            //display: none;
        }
        .orders-list{
            .list_header{
                flex-wrap: wrap !important;
                .navLink{
                    width: 100% !important;
                }
            }
            header{
                h3{
                    font-size: 16px !important;
                }
                .navLink{
                    // background: red !important;
                    //display: flex !important;
                    //flex-direction: column !important;
        
                    .tab-link{
                        //background:orange !important;
                        font-size: 14px !important;
                        padding: 4px 6px !important;
                        margin-top: 1px !important;
                    }
        
                }
            }
        
            /**filter**/
            .order-filter,.filter-check,.filter-progress{
                //background: red !important;
                margin: 10px 0 !important;
                padding: 0 !important;
                //display: none;
                
                button{
                    font-size: 12px !important;
                    padding: 10px 15px  !important;
                }
                
            }
            /**filter**/
        
            .Pagination-all{
               
                .page-link{
                    padding: 3px 5px !important;
                }
                .navigation{
                    padding: 2px 20px  !important;
                    //width: 100% !important;
                    .pagination{
                        padding: 2px 20px  !important;
                        li{
                            padding: 2px 20px  !important;
                            font-size: 12px !important;
        
                        }
                    }
                }
            }
        
        
            
        }
        .model-order{
            
            .modal-content{
                width: 310px;
                margin:50px  auto !important;
                min-height: 250px !important;
                position: absolute;
                left: -180%;
               
               
              
                .text_filed{
                    padding: 3px;
                    
                }
            }
            
            max-width: 120px !important;
            .lan{
               
               // margin: auto !important;
                //background-color: hotpink !important;
                p{
                    font-size: 9px !important;
                }
            }
        }
        /**view**/
        .order-list-view{
            .instructions-all{
                padding: 0 !important;
                .instructions{
                    h5{
                        font-size: 10px!important;
                    }
                    div{
                        p{
                            font-size: 9px !important;
                            //font-weight: 300!important;
        
                        }
                    }
                }
        
            }
            
        }
        
        
        
        .view-result-progress{
            .m-auto{
                min-width: 100% !important;
            }
            .view{
                .selected-field{
                   
                    padding: 0 !important;
                    font-size: 16px !important;
                }
            }
            button{
                padding:3px 5px !important;
            }
        }
        /**view**/
        
        
            
        
    }    

@media (min-width: 715px) and (max-width: 768px){ 
    
/****/

    .orders-list{
    header{
        h3{
            font-size: 16px !important;
        }
        .navLink{
            //background: red !important;
            //display: flex !important;
            //flex-direction: column !important;

            .tab-link{
                //background:orange !important;
                font-size: 16px !important;
                padding: 3px 6px !important;
                margin-top: 1px !important;
            }

        }
    }

    /**filter**/
    .order-filter,.filter-check,.filter-progress{
        //background: red !important;
        margin: 10px 0 !important;
        padding: 0 !important;
        //display: none;
        
        button{
            font-size: 12px !important;
            padding: 12px 9px  !important;
        }
        
    }
    /**filter**/

    // Table {
    //     padding: 2px !important;

    //     tbody {
    //         font-size: 10px !important;
    //     }

    //     td {
    //         padding: 0 !important;
    //         font-size: 12px !important;
    //         button{
    //             width: 15px !important;
    //             font-size: 11px !important;

    //             i{
    //                 font-size: 11px !important;
    //                 display: none !important;
    //             }
    //         }
    //         .submit-operation-order,.submit-traveller-result{
    //             //display: none !important;
            
    //         }

    //         .submit-operation-order,.submit-traveller-result{
    //          font-size: 9px !important;
    //          width: 35px !important;
    //         }
    //     }

    //     th {
    //         font-size: 12px !important;
    //         font-weight: 100 !important;
    //         padding: 5px 0 !important;


    //     }

    // }
    // .check-table,.progress-table{
    //     th,td{
    //         font-size: 11px !important;
    //     }
    // }
    .Pagination-all{
       
        .page-link{
            padding: 3px 5px !important;
        }
        .navigation{
            padding: 2px 20px  !important;
            //width: 100% !important;
            .pagination{
                padding: 2px 20px  !important;
                li{
                    padding: 2px 20px  !important;
                    font-size: 12px !important;

                }
            }
        }
    }


    
}
.model-order{
    .modal-content{
        width: 500px;
        margin:50px  auto !important;
        min-height: 220px !important;
        position: absolute;
        left: -150%;
        .text_filed{
            padding: 3px;
        }
    }
    
    max-width: 120px !important;
    .lan{
       
       // margin: auto !important;
        //background-color: hotpink !important;
        p{
            font-size: 14px !important;
        }
    }
}
/**view**/
.order-list-view{
    .instructions-all{
        padding: 0 !important;
        .instructions{
            h5{
                font-size: 10px!important;
            }
            div{
                p{
                    font-size: 9px !important;
                    //font-weight: 300!important;

                }
            }
        }

    }
    
}



.view-result-progress{
    .m-auto{
        max-width: 96% !important;
       
    }
    .view{
        .selected-field{
           
            padding: 0 !important;
            font-size: 16px !important;
        }
    }
    button{
        padding:3px 5px !important;
    }
}
/**view**/


    



/****/
    }

    @media (min-width: 768px) and (max-width: 991px) { 
    
        /****/

    .orders-list{
        header{
            h3{
                font-size: 16px !important;
            }
            .navLink{
                //background: red !important;
                //display: flex !important;
                //flex-direction: column !important;
    
                .tab-link{
                    //background:orange !important;
                    font-size: 16px !important;
                    padding: 3px 6px !important;
                    margin-top: 1px !important;
                }
    
            }
        }
    
        /**filter**/
        .order-filter,.filter-check,.filter-progress{
            //background: red !important;
            margin: 10px 0 !important;
            padding: 0 !important;
            //display: none;
            
            button{
                font-size: 12px !important;
                padding: 12px 11px  !important;
            }
            
        }
        /**filter**/
    
        // Table {
        //     padding: 2px !important;
    
        //     tbody {
        //         font-size: 10px !important;
        //     }
    
        //     td {
        //         padding: 0 !important;
        //         font-size: 12px !important;
        //         button{
        //             width: 32px !important;
        //             font-size: 11px !important;
    
        //             i{
        //                 font-size: 11px !important;
        //                 display: none !important;
        //             }
        //         }
        //         .submit-operation-order,.submit-traveller-result{
        //             //display: none !important;
                
        //         }
    
        //         .submit-operation-order,.submit-traveller-result{
        //          font-size: 10px !important;
        //          width: 86px !important;
        //         }
        //         .submit-traveller-result{
        //             font-size: 9px !important;
        //              width: 64px !important;
        //         }
        //     }
    
        //     th {
        //         font-size: 12px !important;
        //         font-weight: 100 !important;
        //         padding: 5px 0 !important;
    
    
        //     }
    
        // }
        // .check-table,.progress-table{
        //     th,td{
        //         font-size: 11px !important;
        //     }
        // }
        .Pagination-all{
           
            .page-link{
                padding: 3px 5px !important;
            }
            .navigation{
                padding: 2px 20px  !important;
                //width: 100% !important;
                .pagination{
                    padding: 2px 20px  !important;
                    li{
                        padding: 2px 20px  !important;
                        font-size: 12px !important;
    
                    }
                }
            }
        }
    
    
        
    }
    .model-order{
        .modal-content{
            width: 550px;
            margin:50px  auto !important;
            min-height: 300px !important;
            position: absolute;
            left: -150%;
           
            
          
            .text_filed{
                padding: 3px;
            }
        }
        
        max-width: 120px !important;
        .lan{
           
           // margin: auto !important;
            //background-color: hotpink !important;
            p{
                font-size: 14px !important;
            }
        }
    }
    /**view**/
    .order-list-view{
        .instructions-all{
            padding: 0 !important;
            .instructions{
                h5{
                    font-size: 10px!important;
                }
                div{
                    p{
                        font-size: 9px !important;
                        //font-weight: 300!important;
    
                    }
                }
            }
    
        }
        
    }
    
    
    
    .view-result-progress{
        .m-auto{
            max-width: 96% !important;
           
        }
        .view{
            .selected-field{
               
                padding: 0 !important;
                font-size: 16px !important;
            }
        }
        button{
            padding:3px 5px !important;
        }
    }
    /**view**/
    
    
     }
    
     @media (min-width: 992px) and (max-width: 1199px) { 
        .orders-list{
           
            /**filter**/
        .order-filter,.filter-check,.filter-progress{
            //background: red !important;
            margin: 10px 0 !important;
            padding: 0 !important;
            //display: none;
            
            button{
                font-size: 12px !important;
                padding: 12px 11px  !important;
            }
            
        }
        /**filter**/

       
        }
        .model-order{
            .modal-content{
                width: 740px;
                margin:50px  auto !important;
                min-height: 265px !important;
                position: absolute;
                left: -260%;
                
              
                .text_filed{
                    padding: 3px;
                }
            }
            
            max-width: 120px !important;
            .lan{
               
               // margin: auto !important;
                //background-color: hotpink !important;
                p{
                    font-size: 18px !important;
                }
            }
        }
      }
    
//       @media (min-width: 1200px) { 
    
//         .login-auth{
//             display: flex;
            
//             justify-content: space-between;
//             .logo{
//                 width: 30% !important;
//             }
//             .login-controls{
//                 width: 32% !important;
    
//             }
//         }
//        }