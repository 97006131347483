@media (max-width: 320px) {
    .payments {
        padding: 0px !important;
        overflow-x: hidden !important;

        header {
            padding: 5px 0px !important;

            h3 {
                font-size: 12px !important;
            }
        }

        .payment-cost {

            .boxs {
                display: block !important;

                .box {
                    max-width: 100% !important;
                    margin: 5px auto !important;

                    h3 {
                        font-size: 14px !important;
                    }

                    h4 {
                        font-size: 18px !important;
                    }
                }
            }
        }

        .payment-filter {

            display: none !important;
        }

       
        .Pagination-all{
            display: none !important;
            *{
                padding: 0   !important;
                //width: 100% !important;
            }
            .navigation{
                padding: 2px 20px  !important;
                width: 100% !important;
                .pagination{
                    padding: 2px 20px  !important;
                    li{
                        padding: 2px 20px  !important;
                        font-size: 12px !important;
   
                    }
                }
            }
        }

    }

}

@media (min-width: 320px) and (max-width: 635px){ 
    .payments {
        padding: 0px !important;
        overflow-x: hidden !important;
        *{
            background-image: none !important;
        }

        header {
            padding: 5px 0px !important;

            h3 {
                font-size: 12px !important;
            }
        }

        .payment-cost {

            .boxs {
                max-width: 100% !important;
                display: flex !important;
                flex-wrap: wrap !important;
                justify-content: center !important;
                align-items: center !important;

                .box {
                    max-width: 85% !important;
                    margin: 5px auto !important;

                    h3 {
                        font-size: 14px !important;
                    }

                    h4 {
                        font-size: 18px !important;
                    }
                }
            }
        }

        .payment-filter {
           
            *{
                margin: 0 !important;
            }
            button{
                font-size: 12px !important;
                padding: 10px 15px  !important;
            }
        }

       
        .Pagination-all{
            .page-link{
                padding: 3px 5px !important;
            }
            .navigation{
                padding: 2px 20px  !important;
                width: 100% !important;
                .pagination{
                    padding: 2px 20px  !important;
                    li{
                        padding: 2px 20px  !important;
                        font-size: 12px !important;
   
                    }
                }
            }
        }





       

    }

    .model-order1{
        .modal-content{
            
           width: 350px !important ;
            //min-width: 10px !important;

           // margin:50px  auto !important;
            min-height: 200px !important;
            position: absolute;
            left: -150% !important ;
           

          
            .text_filed{
                padding: 3px;
                
            }
        }
        
        max-width: 150px !important;
        .lan{
           
           margin: 0 !important;
           ///padding: 0 !important;
            //background-color: hotpink !important;
            p{
                font-size: 11px !important;
            }
        }
        }
    


}

@media (min-width: 636px) and (max-width: 768px){ 
   
    .payments {
        padding: 0px !important;
        overflow-x: hidden !important;
        *{
            background-image: none !important;
        }

        header {
            padding: 7px 5px !important;

            h3 {
                font-size: 16px !important;
            }
        }

        .payment-cost {

            .boxs {
                max-width: 100% !important;
                display: flex !important;
                flex-wrap: wrap !important;
                justify-content: center !important;
                align-items: center !important;

                .box {
                    max-width: 48% !important;
                    margin: 5px auto !important;

                    h3 {
                        font-size: 14px !important;
                    }

                    h4 {
                        font-size: 18px !important;
                    }
                }
            }
        }

        .payment-filter {
            
            *{
                margin: 0 !important;
            }
            button{
                font-size: 12px !important;
                padding: 10px 15px  !important;
            }
        }

       
        .Pagination-all{
            .page-link{
                padding: 5px 7px !important;
            }
           
            .navigation{
                padding: 2px 20px  !important;
                width: 100% !important;
                .pagination{
                    padding: 2px 20px  !important;
                    li{
                        padding: 2px 20px  !important;
                        font-size: 12px !important;
                        
   
                    }
                }
            }
        }

    }



}
@media (min-width: 768px) and (max-width: 991px) { 

        .payments {
            padding: 0px !important;
            overflow-x: hidden !important;
            *{
                background-image: none !important;
            }
    
            header {
                padding: 10px 10px !important;
    
                h3 {
                    font-size: 19px !important;
                }
            }
    
            .payment-cost {
    
                .boxs {
                    max-width: 100% !important;
                    display: flex !important;
                    flex-wrap: wrap !important;
                    justify-content: center !important;
                    align-items: center !important;
    
                    .box {
                        max-width: 30% !important;
                        margin: 5px auto !important;
    
                        h3 {
                            font-size: 14px !important;
                        }
    
                        h4 {
                            font-size: 18px !important;
                        }
                    }
                }
            }
    
            .payment-filter {
                
                *{
                    margin: 0 !important;
                }
                button{
                    font-size: 12px !important;
                    padding: 10px 15px  !important;
                }
            }
    
            .Pagination-all{
                .page-link{
                    padding: 5px 7px !important;
                }
               
                .navigation{
                    padding: 2px 20px  !important;
                    width: 100% !important;
                    .pagination{
                        padding: 2px 20px  !important;
                        li{
                            padding: 2px 20px  !important;
                            font-size: 12px !important;
                            
       
                        }
                    }
                }
            }
    
        }














}

@media (min-width: 992px) and (max-width: 1199px) { 
        
        .payments {
            padding: 0px !important;
            overflow-x: hidden !important;
            *{
                background-image: none !important;
            }
    
            header {
                padding: 10px 10px !important;
    
                h3 {
                    font-size: 20px !important;
                }
            }
    
            .payment-cost {
    
                .boxs {
                    max-width: 100% !important;
                    display: flex !important;
                    flex-wrap: wrap !important;
                    justify-content: center !important;
                    align-items: center !important;
    
                    .box {
                        max-width: 32% !important;
                        margin: 5px auto !important;
    
                        h3 {
                            font-size: 14px !important;
                        }
    
                        h4 {
                            font-size: 18px !important;
                        }
                    }
                }
            }
    
            .payment-filter {
                
                
                button{
                    font-size: 12px !important;
                    padding: 10px 15px  !important;
                }
            }
    
          
            .Pagination-all{
                .page-link{
                    padding: 5px 7px !important;
                }
               
                .navigation{
                    padding: 2px 20px  !important;
                    width: 100% !important;
                    .pagination{
                        padding: 2px 20px  !important;
                        li{
                            padding: 2px 20px  !important;
                            font-size: 12px !important;
                            
       
                        }
                    }
                }
            }
    
        }
}

//       @media (min-width: 1200px) { 

//         .login-auth{
//             display: flex;

//             justify-content: space-between;
//             .logo{
//                 width: 30% !important;
//             }
//             .login-controls{
//                 width: 32% !important;

//             }
//         }
//        }