@media (max-width: 320px) {
    .settings {
        header {
            h3 {
                font-size: 16px !important;
            }
        }

        /**SettingOperation**/
        .checks {
            display: flex !important;
            flex-wrap: wrap !important;
            justify-content: center !important;
            align-items: center !important;

            input[type="checkbox"] {
                box-sizing: border-box;
                padding: 0;
                max-width: 11px !important;
                border-style: solid !important
            }

            input[type="checkbox"]::before {
                content: "";
                width: 100% !important;
                margin: 0 !important;
            }

            h3 {
                font-size: 14px !important;
            }

            .check-content {
                padding: 0 !important;
                margin: 0 !important;
                //max-width: 100% !important;

                .checks {
                    //max-width: 20px !important;
                    margin: 0 !important;

                    label {
                        font-size: 10px !important;
                    }
                }

            }
        }

        input[type="checkbox"]::before {
            content: "";
            position: absolute;

            border-style: solid;
            border-width: 0px !important;
            border-color: none !important;
        }

        .day {
            width: 30px !important;
        }

        .controls {
            //overflow-x:hidden !important ;
            display: flex !important;
            flex-wrap: wrap !important;
            justify-content: start !important;
            align-items: flex-start !important;

            h3 {
                font-size: 9px !important;
                margin: 0 !important;
            }

            .text_filed {
                * {
                    font-size: 5px !important;
                }

                input[type=time] {
                    width: 50px !important;
                }
            }
        }

        /**SettingOperation**/

        /**SpecialSettings*/
        .special-settings {

            .add-settings {
                .m-auto {
                    width: 100% !important;

                    * {
                        margin: 0 !important;
                        font-size: 10px !important;

                    }
                }

                button {
                    display: none !important;
                }

                .special-settings1 {
                    margin-bottom: 15px !important;

                    * {
                        max-width: 100% !important;
                    }
                }

            }

            .sseting {
                button {
                    font-size: 12px !important;

                }

            }
        }

        /**SpecialSettings*/

    }

}


@media (min-width: 320px) and (max-width: 768px) {

    .settings {
        header {
            h3 {
                font-size: 16px !important;
            }
        }

        /**SettingOperation**/
        .checks {
            display: flex !important;
            flex-wrap: wrap !important;
            justify-content: start !important;
            align-items: center !important;

            input[type="checkbox"] {
                box-sizing: border-box;
                padding: 0;
                max-width: 11px !important;
                border-style: solid !important
            }

            input[type="checkbox"]::before {
                content: "";
                
                width: 100% !important;
               
                margin: 0 !important;
            }

            h3 {
                font-size: 16px !important;
                //max-width: 100% !important;
            }

            .check-content {
                padding: 0 !important;
                margin: 0 !important;
                //max-width: 100% !important;

                .checks {
                    //max-width: 20px !important;
                    margin: 0 !important;

                    label {
                        font-size: 10px !important;
                    }
                }

            }
        }

        input[type="checkbox"]::before {
            content: "";
            position: absolute;
            border-style: solid;
            border-width: 0px !important;
            border-color: none !important;
        }

        .day {
            width: 36px !important;
        }

        .controls {
            display: flex !important;
            flex-wrap: nowrap !important;
            justify-content: start !important;
            align-items: center !important;

            h3 {
                font-size: 16px !important;
            }

            .text_filed {
                margin: 0 !important;
                padding: 0 !important;

                * {
                    font-size: 11px !important;
                }

                input[type=time] {
                    width: 80px !important;
                }
            }
        }

        /**SettingOperation**/

        /**SpecialSettings*/
        .special-settings {
            .add-settings {
                padding: 0 !important;

                .m-auto {
                    min-width: 100% !important;

                    * {
                        margin: 0 !important;
                    }
                }

                button {
                    margin: 10px 0 !important;
                }

                .special-settings1 {
                    margin-bottom: 15px !important;

                    * {
                        max-width: 100% !important;
                    }
                }
            }

            .sseting {
                button {
                    font-size: 14px !important;

                }
            }

        }

        /**SpecialSettings*/
        .save {
            padding: 7px 5px !important;
        }
    }

}

@media (min-width: 768px) and (max-width: 991px) {

    .settings {
        header {
            h3 {
                font-size: 16px !important;
            }
        }

        /**SettingOperation**/
        .checks {
            display: flex !important;
            flex-wrap: wrap !important;
            justify-content: start !important;
            align-items: center !important;

            input[type="checkbox"] {
                box-sizing: border-box;
                padding: 0;
                max-width: 11px !important;
                border-style: solid !important
            }

            input[type="checkbox"]::before {
                content: "";
                margin: 0 !important;
            }

            h3 {
                font-size: 18px !important;
            }

            .check-content {
                padding: 0 !important;
                margin: 0 !important;

                .checks {
                    margin: 0 !important;

                    label {
                        font-size: 16px !important;
                    }
                }

            }
        }

        input[type="checkbox"]::before {
            content: "";
            position: absolute; 
            border-style: solid;
            border-width: 0px !important;
            border-color: none !important;
        }

        .day {
            width: 80px !important;

        }

        .controls {
            //overflow-x:hidden !important ;
            display: flex !important;
            flex-wrap: wrap !important;
            justify-content: start !important;
            align-items: center !important;

            h3 {
                font-size: 18px !important;
            }

            .text_filed {

                * {
                    font-size: 13px !important;
                }

                input[type=time] {
                    width: 150px !important;
                }
            }
        }

        /**SettingOperation**/

        /**SpecialSettings*/
        .special-settings {

            .add-settings {
                .m-auto {
                    width: 100% !important;

                    * {
                        margin: 0 !important;

                    }
                }

                button {
                }

                .special-settings1 {
                    margin-bottom: 15px !important;

                    * {
                        max-width: 100% !important;
                    }
                }
            }

            .sseting {
                button {
                    font-size: 16px !important;

                }

            }
        }

        /**SpecialSettings*/

    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .settings {
        header {
            h3 {
                font-size: 16px !important;
            }
        }

        /**SettingOperation**/
        .checks {
            display: flex !important;
            flex-wrap: wrap !important;
            justify-content: start !important;
            align-items: center !important;

            input[type="checkbox"] {
                box-sizing: border-box;
                padding: 0;
                max-width: 11px !important;
                border-style: solid !important
            }

            input[type="checkbox"]::before {
                content: "";
                margin: 0 !important;
            }

            h3 {
                font-size: 18px !important;
            }

            .check-content {
                padding: 0 !important;
                margin: 0 !important;

                .checks {
                    margin: 0 !important;

                    label {
                        font-size: 18px !important;
                    }
                }

            }
        }

        input[type="checkbox"]::before {
            content: "";
            position: absolute;
            //height: 100% !important; 
            //width: 100% !important; 
            border-style: solid;
            border-width: 0px !important;
            border-color: none !important;
        }

        .day {
            width: 95px !important;

        }

        .controls {
            //overflow-x:hidden !important ;
            display: flex !important;
            flex-wrap: wrap !important;
            justify-content: start !important;
            align-items: center !important;

            h3 {
                font-size: 18px !important;
            }

            .text_filed {

                * {
                    font-size: 13px !important;
                }

                input[type=time] {
                    //background-color: red !important;
                    width: 150px !important;
                }
            }
        }

        /**SettingOperation**/

        /**SpecialSettings*/
        .special-settings {

            //background-color: tan !important;
            .add-settings {
                .m-auto {
                    width: 100% !important;

                    * {
                        margin: 0 !important;
                        // font-size: 10px !important;

                    }
                }

                button {
                    // display: none !important;
                }

                .special-settings1 {
                    margin-bottom: 15px !important;

                    * {
                        max-width: 100% !important;
                    }
                }
            }

            .sseting {
                button {
                    font-size: 16px !important;

                }

                //display: none !important;
            }
        }

        /**SpecialSettings*/

    }

}

@media (min-width: 1200px) {

    .settings {

        /**SettingOperation**/



        /**SettingOperation**/

        /**SpecialSettings*/
        .special-settings {

            //background-color: tan !important;
            .add-settings {
                .m-auto {
                    width: 100% !important;

                    * {
                        margin: 0 !important;
                        // font-size: 10px !important;

                    }
                }

                button {
                    // display: none !important;
                }

                .special-settings1 {
                    margin-bottom: 15px !important;

                    * {
                        max-width: 100% !important;
                    }
                }
            }

            .sseting {
                button {
                    font-size: 16px !important;

                }

                //display: none !important;
            }
        }

        /**SpecialSettings*/

    }
}