
html[dir="rtl"] .mr-1 {
  margin-left: 0.25rem !important;
 margin-right: 0rem !important;
}
html[dir="rtl"] .mr-2 {
  margin-left: 0.5rem !important;
  margin-right: 0rem !important;
}
html[dir="rtl"] .mr-3 {
  margin-left: 1rem !important;
 margin-right: 0rem !important;
}
html[dir="rtl"] .mr-4 {
  margin-left: 1.5rem !important;
   margin-right: 0rem !important;
}
html[dir="rtl"] .mr-5 {
  margin-left: 3rem !important;
   margin-right: 0rem !important;
}

html[dir="rtl"] .ml-1 {
  margin-right: 0.25rem !important;
  margin-left: 0rem !important;
}
html[dir="rtl"] .ml-2 {
  margin-right: 0.5rem !important;
  margin-left: 0rem !important;

}
html[dir="rtl"] .ml-3 {
  margin-right: 1rem !important;
  margin-left: 0rem !important;

}
html[dir="rtl"] .ml-4 {
  margin-right: 1.5rem !important;
  margin-left: 0rem !important;

}
html[dir="rtl"] .ml-5 {
  margin-right: 3rem !important;
  margin-left: 0rem !important;

}

/********/
html[dir="ltr"]{
  //background-color: red;
  font-family: 'Cairo' !important;
  
}
html[dir="ltr"] .header-icons .settings>*{
  //background-color: red;
  font-family: 'Cairo' !important;
  
}

