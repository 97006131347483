@media (max-width: 320px) {
    img {
        width: 35px !important;
        height: 35px !important;
    }

    .order-dots {

        //display: none !important;
        h4 {
            font-size: 12px !important;

            i {
                font-size: 10px !important;
            }
        }

    }

    .dashboard-companies {
        overflow-x: hidden !important;

        * {
            padding: 0px;
        }
    }

    #chart {
        width: 98% !important;
        display: flex;
        justify-content: center !important;

        * {
            font-size: 11px !important;
        }

    }

    .orders {
        height: 85px !important;

        div.row {
            padding: 0 !important;
            margin: 0 !important;
        }

    }

    .main {
        i {
            font-size: 9px !important;
        }

        h5 {
            font-size: 15px !important;
            font-weight: 300 !important;

        }

        .signin-head {
            font-size: 12px !important;
            font-weight: 100 !important;
        }
    }
}

@media (min-width: 320px) and (max-width: 768px) {
    .count-box{
    }
    img {
        width: 38px !important;
        height: 38px !important;
    }

    .order-dots {
        text-align: center !important;
        //display: none !important;
        h4 {
            font-size: 14px !important;

            i {
                font-size: 12px !important;
            }
        }

    }

    .dashboard-companies {
        overflow-x: hidden !important;

        * {
            padding: 0px;
        }
    }

    #chart {
        width: 98% !important;
        display: flex;
        justify-content: center !important;

        * {
            font-size: 12px !important;
        }

    }

    .orders {
        height: 85px !important;

        div.row {
            padding: 0 !important;
            margin: 0 !important;
        }

    }

    .main {
        i {
            font-size: 9px !important;
        }

        h5 {
            font-size: 15px !important;
            font-weight: 300 !important;

        }

        .signin-head {
            font-size: 12px !important;
            font-weight: 100 !important;
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) { 

        .count-box{
            min-width: 100%;
        }
        img {
            width: 38px !important;
            height: 38px !important;
        }
    
        .order-dots {
            text-align: center !important;
            //display: none !important;
            h4 {
                font-size: 14px !important;
    
                i {
                    font-size: 12px !important;
                }
            }
    
        }
    
        .dashboard-companies {
            overflow-x: hidden !important;
    
            * {
                padding: 0px;
            }
        }
    
        #chart {
            width: 98% !important;
            display: flex;
            justify-content: center !important;
    
            * {
                font-size: 14px !important;
            }
    
        }
    
        .orders {
            height: 85px !important;
    
            div.row {
                padding: 0 !important;
                margin: 0 !important;
            }
    
        }
    
        .main {
            i {
                font-size: 9px !important;
            }
    
            h5 {
                font-size: 15px !important;
                font-weight: 300 !important;
    
            }
    
            .signin-head {
                font-size: 12px !important;
                font-weight: 100 !important;
            }
        }
 }

@media (min-width: 992px) and (max-width: 1199px) { 
        

        .count-box{
            min-width: 100%;
        }
        img {
            width: 35px !important;
            height: 35px !important;
        }
    
        .order-dots {
            text-align: center !important;
            //display: none !important;
            h4 {
                font-size: 18px !important;
    
                i {
                    font-size: 12px !important;
                }
            }
    
        }
    
        .dashboard-companies {
            overflow-x: hidden !important;
    
            * {
                padding: 0px;
            }
        }
    
        #chart {
            width: 98% !important;
            //min-height: 200px !important    ;
            //background: red !important;
            display: flex;
            justify-content: center !important;
    
            * {
                font-size: 14px !important;
            }
    
        }
    
        .orders {
            max-height: 350px !important;
            div.row {
                padding: 0 !important;
                margin: 0 !important;
            }
    
        }
    
        .main {
            i {
                font-size: 11px !important;
            }
    
            h5 {
                font-size: 15px !important;
                font-weight: 300 !important;
    
            }
    
            .signin-head {
                font-size: 12px !important;
                font-weight: 100 !important;
            }
        }
 }

 @media (min-width: 1200px) {  
  }