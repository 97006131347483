.collapse {
    padding: 0 !important;
    margin: -7px !important;

    .navbar-nav {
        padding: 0 !important;

        div {
            margin: 0px !important;

            .navbar-links {}
        }
    }
}

.show-nav {
    padding: 0px !important;
    margin: 0px !important;
    overflow: hidden !important;


    .collapse {
        padding: 0 !important;
        width: 100%;

        div {
            padding: 0 !important;
            width: 100% !important;

            .navbar-links {
                display: flex;
                flex-direction: column;
                justify-content: space-between !important;
                padding: 0 !important;
                text-align: center !important;
                margin: 8px 0 !important;

                a:hover {
                    background-color: #1777F6 !important;
                    margin: 0 !important;

                }

                .is-active {
                    margin: 0 !important;
                }


            }
        }
    }
}







@media (max-width: 320px) {

    .header-containt {
        width: 100% !important;

        div {
            width: 100% !important;

            .header-items {
                width: 100% !important;

                .logo {
                    //width: 50% !important;
                    display: none;
                }

                .notification {
                    .notify-icon {
                        font-size: 16px !important;

                        .notify-number {
                            width: 15px !important;
                            height: 15px !important;
                            font-size: 12px !important;
                        }
                    }

                    .notify-items{
                        .DropdownItem{
                            padding: 0px !important;
                            min-width: 150% !important;
                            h5,p{
                                padding: 0px 2px !important;
                            }

                        }
                        .notify-show-more{
                            min-width: 150% !important;

                        }
                    }
                }
            }
        }

        .settings-head{
            i{
                font-size: 16px !important;
            }
            .Header-Setting-title{
                font-size: 16px !important;
            }
        }
    }


}


@media (min-width: 320px) and (max-width: 767px){ 
    .header-containt {
        //width: 100% !important;

        div {
            //width: 100% !important;

            .header-items {
               // width: 100% !important;
               

                .logo {
                    //width: 50% !important;
                    display: none;
                }

                .notification {
                    .notify-icon {
                        font-size: 16px !important;

                        .notify-number {
                            width: 15px !important;
                            height: 15px !important;
                            font-size: 12px !important;
                        }
                    }

                    .notify-items{
                        .DropdownItem{
                            padding: 0px !important;
                            //min-width: 130% !important;
                            h5,p{
                                padding: 0px 2px !important;
                            }

                        }
                        .notify-show-more{
                          //  min-width: 130% !important;

                        }
                    }
                }
            }
        }

        .settings-head{
            i{
                font-size: 16px !important;
            }
            .Header-Setting-title{
                font-size: 16px !important;
            }
        }
       
    }
    .header-icons{
        width: 100% !important;
        display: flex !important;
        justify-content: space-around !important;
    }
    }
    @media (min-width: 768px) and (max-width: 991px) { 
        .header-containt {
            //width: 100% !important;

            div {
                //width: 100% !important;
    
                .header-items {
                    //width: 100% !important;

                    .logo {
                        width: 20% !important;
                       // display: none;
                    }
    
                    .notification {
                        .notify-icon {
                            font-size: 16px !important;
    
                            .notify-number {
                                width: 15px !important;
                                height: 15px !important;
                                font-size: 12px !important;
                            }
                        }
    
                        .notify-items{
                            .DropdownItem{
                                padding: 0px !important;
                                min-width: 90% !important;
                                h5,p{
                                    padding: 0px 2px !important;
                                }
    
                            }
                            .notify-show-more{
                               min-width: 90% !important;
    
                            }
                        }
                    }
                }
            }
    
            .settings-head{
                i{
                    font-size: 16px !important;
                }
                .Header-Setting-title{
                    font-size: 16px !important;
                }
            }
        }
       
     }

//      @media (min-width: 992px) and (max-width: 1199px) { 
//         .login-auth{
//             display: flex;

//             justify-content: space-between;
//             .logo{
//                 width: 35% !important;
//             }
//             .login-controls{
//                 width: 40% !important;
//             }
//         }
//       }

//       @media (min-width: 1200px) { 

//         .login-auth{
//             display: flex;

//             justify-content: space-between;
//             .logo{
//                 width: 30% !important;
//             }
//             .login-controls{
//                 width: 32% !important;

//             }
//         }
//        }