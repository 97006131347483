
.text_filed {
  border: 1px solid #eae8e9;
  padding: 2px;
  border-radius: 5px;
  box-shadow: none;
  font-size: 16px;
  /****/
  //height: 43px !important;

}
.padding_disable{
   padding: 2px;
}
.comany-medical-filter .text_filed{
  height: 43px !important;
}
.text_filed input[type=time]
{
  //border:5px solid  red !important;
  width: 200px !important;
  text-align: end;

}
.text_filed input[type=time]::-webkit-calendar-picker-indicator { 
  //display: none !important;
} 


.error_border {
  border: 1px solid #ad0a0a !important;
}
.text_input {
  width: 100%;
  outline: none;
  font-size: 16px;
  box-shadow: none;
  border: none;
}

.text_input::placeholder {
  font-size: 14px;
  padding: 0.5rem;
  //color: #112233;
  font-weight: 400;
}

.DateInput_input {
  width: 100%;
  outline: none;
  padding: 0rem !important;
  font-size: 16px !important;
  box-shadow: none;
  border: none;
  font-weight: 400 !important;
  border: none !important;
}
.DateInput {
  width: 100% !important;
}
.DateInput_input::placeholder {
  //color: #112233;
  padding: 0rem 0.6rem !important ;
  font-size: 14px;
}

.SingleDatePickerInput,
.SingleDatePicker {
  width: 100% !important;
}
.select_field {
  background-color: rgb(255, 255, 255);
  width: 100% !important;
  border-radius: 5px !important;

  border: 1px solid #eae8e9 !important;
  font-size: 14px;
}
.select_field--label {
  background-color: rgb(255, 255, 255);
  width: 100% !important;
  position: relative;
  border-radius: 5px !important;
  padding-top: 0.9rem !important;
  
  font-size: 14px;
}
/////*********/
.bg-white.select_field--label.border.border-.css-b62m3t-container { //

  //border: none !important;
  //align-items: end !important;
   padding: 0 !important;
  //background-color: red !important;
    [class*="-control"]{ //.css-1s2u09g-control
    //background-color: orange !important;
    padding: 12px;
  }
}
//***********/
.css-19jh2ze-option,
.css-165luzk-option {
  color: #112233 !important;
}
.css-14dclt2-Input {
  margin: 0 !important;
  padding: 0 !important;
}
.css-14el2xx-placeholder {
  //color: #112233 !important;
  padding: 0rem 0.4rem !important ;
  font-weight: 400 !important;
}
.control-field__label {
  z-index: 1;
  position: absolute;
  margin: 0;
  padding: 0.4rem !important;
  font-size: 13px !important;
  color: #757575;
}
.control-field {
  z-index: 1;
  margin: 0;
  padding: 0rem 0.4rem;
  font-size: 13px !important;
  color: #757575;
}
/***/
.comany-medical-filter{
  
  [class*="-control"] { //.css-1s2u09g-control
  background: #eae8e9 !important;
  background-color: #FFFFFF !important;
  //border:2px solid red !important;
  }
}

.check-filter{
  
  [class*="-control"] { //.css-1s2u09g-control
  background: #eae8e9 !important;
  background-color: #FFFFFF !important;
  border:2px solid #fff !important;
  }
  button{
    padding: 15px;
  }
  
}
/***/
.bg-disabled {
  background-color: #eae8e9;
  .css-1s2u09g-control {
    background: #eae8e9 !important;
   
  }
  .DateInput_input__disabled {
    background: #eae8e9 !important;
    font-style: normal;
    padding: 0px;
  }
  .SingleDatePickerInput__disabled {
    background: #eae8e9 !important;
  }
}

.icons_label {
  font-size: 28px;
  color: #757575;
}
.icons {
  color: #d6d6d6;
  font-size: 20px;
  margin: 0.4rem 0rem;
}

.radio_bnt {
  width: 25px;
  height: 25px;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 30px;
  height: 30px;
  border: 2px solid #656565;
  border-radius: 50%;
  outline: none;
}
input[type="radio"]:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-top: 2px;
  margin-right: 2px;
  margin-left: 2px;
}

input[type="radio"]:checked:before {
  background: #1677f6;
}
input[type="radio"]:checked {
  border: 3px solid #1677f6;
}
 .css-1s2u09g-control:hover{
  border: none;
 }
 .css-1pahdxg-control{
   box-shadow: none !important;
   border: none !important;
 }
.settings-time-control
{
  //background-color: red !important;
}
.css-4ljt47-MenuList>*,.css-26l3qy-menu>*{
  z-index: 222222222;
}
#react-select-3-listbox,#react-select-7-listbox,[id*="-listbox"]
{
  z-index: 222222222 !important; 
}
#email-helper-text , #component-error-text , #phoneNumber-helper-text , #userName-helper-text{
  font-family: "cairo";
  display: flex;
  justify-content: flex-start;
}
.MuiIconButton-label .MuiSvgIcon-root path
{
  color:#1877FA !important;
}

.selected-field
{
  
  height: 45px !important ;
}
.css-1s2u09g-control{
  height: 52px !important;
}
.comany-medical-filter{
  .css-1s2u09g-control{
    height: 43px !important;
  } 
}
.team-filter{
  .css-1s2u09g-control{
    height: 47px !important;
  } 
}
.view{
  .css-1s2u09g-control{
    height: 40px !important;
  } 
}
/*****special-settings******/
.special-settings{

  .special-settings1 .css-1s2u09g-control{
    height: 43px !important;
  } 
  .special-settings2 .css-1s2u09g-control{
    height: 53px !important;
  }
   
}
/*****special-settings******/

.border-view{
  border: 1px solid rgb(228, 226, 226)
  ;
}
.auth-login{
  background-image: url("../../assets/images/bg.jpg") !important;
  background-color: red !important;
  height: 100vh;
}
.auth-bg{
  background-image: url("../../assets/images/bg.jpg") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
