@media (max-width: 320px) { 
    .login-auth{
        display: flex !important;
        flex-wrap: wrap !important;
        justify-content: start !important;
        align-items: center !important;
        .logo{
            min-width: 70% !important;
            margin: auto;
        }
        .login-controls{
            min-width: 80% !important;
            margin: auto;
        }
    }
}

@media (min-width: 320px) and (max-width: 768px){ 
    .login-auth{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        
        .logo{
            width: 60% !important;
        }
        .login-controls{
            width: 79% !important;
            margin: auto;
            .login-btn{
                padding: 8px 0 !important;
            }
        }
    }
 }
@media (min-width: 768px) and (max-width: 991px) { 
    
        .login-auth{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            .logo{
                width: 50% !important;
            }
            .login-controls{
                width: 60% !important;


            }
        }
 }
    
@media (min-width: 992px) and (max-width: 1199px) { 
        .login-auth{
            display: flex;
            
            justify-content: space-between;
            .logo{
                width: 35% !important;
            }
            .login-controls{
                width: 40% !important;
            }
        }
}
    
@media (min-width: 1200px) { 
        .login-auth{
            display: flex;
            justify-content: space-between;
            .logo{
                width: 30% !important;
            }
            .login-controls{
                width: 32% !important;
            }
        }
       }