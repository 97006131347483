@media (max-width: 360px) { 
    .notifaction-all
    {
        *{
            width: 100% !important;
           
        }
        .notify-content{
            padding: 0 !important;
        }
        h4,h3{
            font-size: 14px !important;
        }
        p{
            font-size: 12px !important;
        }
        i{
            font-size: 12px !important;
        }
    }
    }


@media (min-width: 360px) and (max-width: 768px){ 
    .notifaction-all
    {
        .notifaction-container,.noty{
            width: 98% !important;
        }
        .page-link{
            padding: 3px 5px !important;
        }
        .notify-content{
            padding: 0 !important;
        }
        h4,h3{
            font-size: 15px !important;
            width: 98% !important;

        }
        p{
            font-size: 13px !important;
        }
        i{
            font-size: 14px !important;
        }
    }
    }
    @media (min-width: 768px) and (max-width: 991px) { 
    
        .notifaction-all
        {
            .notifaction-container{
                width: 98% !important;
            }
            .page-link{
                padding: 3px 5px !important;
            }
            .notify-content{
                padding: 0 !important;
            }
            h4,h3{
                font-size: 18px !important;
            }
            p{
                font-size: 16px !important;
            }
            i{
                font-size: 16px !important;
            }
        }
     }
    
     @media (min-width: 992px) and (max-width: 1199px) { 
        .notifaction-all
        {
            .notifaction-container{
                width: 100% !important;
            }
            .page-link{
                padding: 3px 5px !important;
            }
            .notify-content{
                padding: 0 !important;
            }
            h4,h3{
                font-size: 22px !important;
            }
            p{
                font-size: 18px !important;
            }
            i{
                font-size: 18px !important;
            }
        }
      }
    
      @media (min-width: 1200px) { 
    
        .notifaction-all
        {
            .notifaction-container{
                width: 90% !important;
            }
            
            .notify-content{
                padding: 0 !important;
            }
            h4,h3{
                font-size: 22px !important;
            }
            p{
                font-size: 18px !important;
            }
            i{
                font-size: 18px !important;
            }
        }
       }