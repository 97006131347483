@media (min-width: 300px) and (max-width: 500px) {
    .services {
        header {
            width: 100% !important;
        }
    }

    .services-items {
        min-width: 100% !important;
        padding: 0 !important;

        .instructions {
            max-width: 100% !important;
            padding: 10px 0 !important;
            margin: 10px 0 !important;

            .row {
                max-width: 100% !important;
                display: flex !important;
                justify-content: center !important;
                flex-direction: row !important;
                margin: 0 !important;

                p {
                    font-size: 14px !important;
                }
            }

            .services-btn {

                display: flex !important;
                flex-direction: row !important;
                max-width: 100% !important;

                .btn {
                    border: 1px solid #28a745;
                    font-size: 10px !important;
                    padding: 0 !important;
                    width: 100% !important;
                }

            }
        }
    }
}


@media (min-width: 501px) and (max-width: 900px) {
    .services {
        header {
            width: 100% !important;
        }
    }

    .services-items {
        min-width: 100% !important;
        padding: 0 !important;

        .instructions {
            max-width: 100% !important;
            padding: 10px 0 !important;
            margin: 10px 0 !important;

            .row {
                max-width: 100% !important;
                display: flex !important;
                justify-content: center !important;
                flex-direction: row !important;
                margin: 0 !important;

                p {}
            }

            .services-btn {
                display: flex !important;
                flex-direction: row !important;
                max-width: 100% !important;

                .btn {
                    border: 1px solid #28a745;
                    font-size: 12px !important;
                    padding: 6px 2px !important;
                    width: 100% !important;
                }

            }
        }
    }



}


//     @media (min-width: 768px) and (max-width: 991px) { 

//         .login-auth{
//             display: flex;
//             flex-wrap: wrap;
//             justify-content: center;
//             align-items: center;
//             .logo{
//                 width: 50% !important;
//             }
//             .login-controls{
//                 width: 60% !important;


//             }
//         }
//      }

//      @media (min-width: 992px) and (max-width: 1199px) { 
//         .login-auth{
//             display: flex;

//             justify-content: space-between;
//             .logo{
//                 width: 35% !important;
//             }
//             .login-controls{
//                 width: 40% !important;
//             }
//         }
//       }

//       @media (min-width: 1200px) { 

//         .login-auth{
//             display: flex;

//             justify-content: space-between;
//             .logo{
//                 width: 30% !important;
//             }
//             .login-controls{
//                 width: 32% !important;

//             }
//         }
//        }