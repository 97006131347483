.payments{
.payments-header{
    
    th{
        border: none ;
        color:#818488
    }


}
tbody{
    tr:nth-child(odd){
        background: #EDF2F7 ;
    }
    tr:nth-child(even){
      background: #ffffff ;
  }
}
}

.check-content 
{
    label{
        font-size: 20px !important;
    }
    input[type="checkbox"] {
        height: 25px !important;
        width: 25px !important;
        position: relative;
       
    }
    input[type="checkbox"]::before {
        content: "";
        position: absolute;
        height: 100% !important;
        width: 100%!important;
        border-style: solid;
        border-width: 2px !important;
        border-color: gray;
      }
      input[type="checkbox"]:checked::before {
        height: 100% !important;
        width: 100%!important;
        border: 2px solid transparent !important;
       
      }
}
.add-settings{
    
    .basic-multi-select{
        border: 1px solid #eae8e9 !important;
        border-radius: 5px !important;
        .css-1s2u09g-control{
            padding: 8px 2px !important    ;

        }
        .select__control--is-focused{
            padding: 8px 2px !important    ;
            
        }
    }
   
}

.PaymentList-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.PaymentList-infoIcon {
    color : #1777FB;
    font-size: 21px;
    cursor: pointer;
}

.paymentList-headTitle {
    font-family: "Cairo" !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #007bff;
}

.paymentList-Title {
    font-family: "Cairo" !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #656565;
}