// Directions

@import "~bootstrap/scss/bootstrap";
@import "base/rtl.scss";

//components files
@import "modules/component/component.scss";

//base files
@import "base/general.scss";
@import "base/colors.scss";

//modules files
@import "modules/companies/companies.scss";
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import "modules/companies/tripsData.scss";
@import "modules/companies/pcrReservation.scss";
//Check Me
//-----------Dahboard
@import "modules/dashboard/dashboard.scss";

//-----------Travelers
@import "modules/customers/customers.scss";

//------------workTeam
@import "modules/workTeam/workTeam.scss";
@import "modules/workTeam/Collapse.scss";

//------------Payments
@import "modules/payments/payments.scss";

@import "shared/inputs.scss";
@import "shared/_loader.scss";

//----------Notifications
@import "modules/notification/notification.scss";

//--------Reponsive
@import "modules/responsive/login.scss";
@import "modules/responsive/navbar.scss";
@import "modules/responsive/home.scss";
@import "modules/responsive/services.scss";
@import "modules/responsive/payment.scss";
@import "modules/responsive/settings.scss";
@import "modules/responsive/orders-list.scss";
@import "modules/responsive/team.scss";
@import "modules/responsive/notifaction.scss";

//shared files

// public
body {
  background-color: #f9f9f9;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 1520px !important; /** 1320px*/
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.control-field__input {
  border: none !important;
}

.header-icons {
  width: 320px;
  button {
    display: inline-block;
    .fa-sign-out-alt {
      font-size: 25px;
      color: #007bff;
    }
  }
  .dropdown {
    display: inline-block;
  }
}

@media print {


  * {
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
  }
  header,
  footer,
  aside,
  form {
    display: none;
  }
  .header-container {
    display: none;
  }
  tr {
    td.bg-table_disable {
      background-color: #d9d9d9 !important;
      -webkit-print-color-adjust: exact !important;
      color: rgb(0, 0, 0);
    }
  }

  .bg-table_invoice {
    th {
      background-color: #000000 !important;
      -webkit-print-color-adjust: exact !important;
      color: rgb(255, 255, 255);
    }
  }

  .invoice-holder {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
}
tr {
  td.bg-table_invoice {
    background-color: #000000 !important;
    -webkit-print-color-adjust: exact !important;
    color: rgb(255, 255, 255);
  }
}

tr {
  td.bg-white {
    background-color: #ffffff !important;
    -webkit-print-color-adjust: exact !important;
  }
}

tr {
  td.bg-primary {
    background-color: #009bdc !important;
    -webkit-print-color-adjust: exact !important;
  }
}

tr {
  .procedures-invoice {
    -webkit-print-color-adjust: exact !important;
    text-align: center !important;
    vertical-align: middle !important;
  }
}
tr {
  .bg-procedures_invoice {
    background-color: #e0e0e0 !important;
  }
}
.pagebreak {
  page-break-before: always;
}
.invoice-logo-main {
  background-image: url(../assets/images/invoice/logo.png);
  background-size: 150px;
  background-repeat: no-repeat;
}

/* .remove-print {
    page-break-after: avoid;
    page-break-before: avoid;
    page-break-inside: avoid;
    display: none;
  } */

.onPrint {
  width: 100%;
  min-height: 650px;
  background-color: #007bff;
}

.fill-hight-new {
  min-height: 1100px !important;
}
.invoice-logo-side {
  width: 8%;
  background-image: url(../assets/images/invoice/Path48.png);

  background-repeat: no-repeat;
}

.icons-invoice {
  width: 25px;
}
.footer-width {
  width: 100%;
}

.Header-Setting-img {
  width: 36px;
  height: 36px;
}
.DateTimePicker {
  fieldset {
    border: none !important;
  }
  input {
    padding: 5px;
  }
  button {
    svg {
      width: 28px !important;
      height: 28px !important;
    }
  }
}

div .css-k008qs {
  direction: ltr !important;
}
.disabled-btn{opacity: .5 !important;}