.notify-icon{

    position: relative;
    font-size: 23px ;
   
.notify-number
{
    
    position: absolute;
    background-color: #11C08F !important;
    width: 22px;
    height: 22px;
    font-size: 16px ;
    border-radius: 50%;
    
    color:#fff;
    padding: 2px 0 ;
    //left: 40%;
     bottom: 50% ;

}
}
.no-padding{
    padding: 0px;
}

html[dir="ltr"] .notify-icon .notify-number {
    right: 40% ;
    bottom: 40% ;
  }
   html[dir="rtl"] .notify-icon .notify-number {
    left: 40% ;
    bottom: 40% ;
  }
.notification .dropdown-menu.show{
    //background-color: red !important;
    width: 250px;
}

.notify-show-more{
    background-color: #017BFF;
    font-size: 19px;
    color:#fff;
    h6{
        font-weight: 600;
    }
}
.bg-notify{
    background-color: #F4F8FF !important;
}
.read{
    background-color: #F2F2F2;  
}
.not-read{
    background-color: #F4F8FF;
}
.notify-content{
    color: #414040;
}
