.text-grey {
  color: #818488 !important;
}
.btn-nav {
  padding: 10px 20px !important;
}

.stopping {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: #ad0000 !important;
  font-size: 16px;
}

.accepted {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: #28a745 !important;
  font-size: 16px;
}

.rejected {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: #ad0000 !important;
  font-size: 16px;
}

.square-stop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  background-color: #ad0000;
  height: 29px !important;
  width: 29px !important;
  border-radius: 50%;
  margin-right: 3px;
  margin-left: unset;
}

.WorkTeamInput {
  width: 100%;
}

.WorkTeamInput .MuiInputLabel-filled.MuiInputLabel-shrink {
  color: rgba(0, 0, 0, 0.75);
}

html[dir="rtl"] .WorkTeamInput .MuiInputLabel-filled.MuiInputLabel-shrink {
  left: unset;
  right: 0px;
}

.WorkTeamInput .MuiFilledInput-input {
  color: rgba(0, 0, 0, 0.65);
}

.WorkTeamInput .MuiFilledInput-root {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dee2e6;
  margin-bottom: 15px !important;
}

.WorkTeamInput .MuiFilledInput-root:hover {
  background-color: #fff;
}

.WorkTeamInput .MuiFilledInput-root.Mui-focused {
  background-color: #fff;
}

.WorkTeamInput .MuiFilledInput-underline:before {
  border-bottom: none !important;
}

.WorkTeamInput .MuiFilledInput-underline:after {
  border-bottom: none !important;
}

.WorkTeamInput .MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.54);
}

.WorkTeamInputError .MuiFilledInput-root {
  border-color: #ff5f59 !important;
  margin-bottom: 0px !important;
}

.WorkTeamInput .MuiFormHelperText-root.Mui-error {
  color: #ff5f59 !important;
  margin-left: 0px !important;
}

html[dir="rtl"] .WorkTeamInput .MuiFormHelperText-root.Mui-error {
  text-align: right;
  margin-right: 0px !important;
}

.WorkTeamInputError .MuiFilledInput-root {
  border-color: #ff5f59 !important;
  margin-bottom: 0px !important;
}

.PasswordInputError {
  color: #ff5f59 !important;
  margin-left: 0px !important;
}

.GroupsInputError {
  color: #ff5f59 !important;
  margin-left: 0px !important;
}

html[dir="rtl"] .square-stop-container {
  margin-left: 3px;
  margin-right: unset;
}

.square-stop {
  height: 12px !important;
  width: 12px !important;
  background-color: #fff;
}

.btn-danger {
  background-color: #ad0000;
}

h3,
.h3 {
  font-size: 1.6rem;
}
.table {
  border-collapse: collapse !important;
  border: none !important;
  * {
    border: none;
  }
  thead {
    th {
      text-align: left;
      border: none;
    }
  }
  tr {
    td {
      text-align: left;
      font-size: 16px;
    }
  }
}

html[dir="rtl"] .table thead th {
  text-align: right;
}

html[dir="rtl"] .table tr td {
  text-align: right;
}

.table-stripe {
  tr:nth-child(odd) {
    background: #edf2f7 !important;
  }
  tr:nth-child(even) {
    background: #ffffff !important;
  }
}
.text-green {
  color: #00b437 !important;
}
.controls {
  i {
    color: #9fafbb !important;
    font-size: 18px !important;
  }
}
.max-height {
  height: 40vh !important;
}
.btns {
  .btn {
    padding: 12px 35px !important;
    font-weight: 700;
    color: #fff !important;
  }
  .btn-success {
    padding: 12px 85px !important;
  }
}

.selectUmrah {
  .css-1s2u09g-control {
    height: 46px !important;
  }
}

.css-1s2u09g-control {
  border: none !important;
  padding-bottom: 5px !important;
  text-shadow: none !important;
  height: 42px !important;
}
.css-1pahdxg-control {
  border: none !important;
  padding-bottom: 5px !important;
  text-shadow: none !important;
}

/***************************USER NUMBER**************************************/

.box {
  width: 230px;
  padding: 20px 10px !important;
  background-color: #f2f2f2 !important;
  color: #656565;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
/************************Edit Group************************************/
.all-boxs {
  display: flex;
  margin: 20px 0;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  .boxs {
    width: 22%;
    background-color: #ffffff !important;
    margin-right: 25px;
    margin-bottom: 25px;

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    input[type="radio"],
    input[type="checkbox"] {
      width: 18px !important;
      height: 18px !important;
    }
    .box-header {
      background-color: #f2f2f2 !important;
      padding: 12px 15px !important;
      .label {
        font-weight: 700;
      }
    }
    .box-body {
      padding: 8px 15px !important;
      .label {
        margin: 5px 8px !important;
      }
    }
  }
}

html[dir="rtl"] .all-boxs {
  .boxs {
    margin-left: 25px;
    margin-right: unset;
  }
}

.GroupError {
  color: #ff5f59 !important;
}
html[dir="rtl"] .WorkTeamInput .MuiInputLabel-filled.MuiInputLabel-shrink {
  font-family: "Cairo";
  font-size: 16px !important;
  color: #757575;
  font-weight: 600;
}
.MuiFilledInput-input {
  padding: 27px 12px 10px;
  font-family: "Cairo" !important;
}
.MuiTypography-body1 {
  font-family: "Cairo" !important;
}
