
// body{
//     background-image: url("../../../assets/images/paymentBg.png");
//     background-position:bottom bottom ;
//     background-repeat: no-repeat;
//     background-size: cover;

   
// }

.payment-cost
{
    background-image: url("../../../assets/images/paymentBg.png");
    background-position: -50% 1% ;
    
    background-repeat: no-repeat;
    background-size: cover;
    
   
    
    

    .boxs{
        .box{
            padding: 10px !important;
            background-color: #fff !important;
            border-radius: 8px !important;
           

            .layout{
                background-color: #DEEAF3 ;
                border-radius: 8px !important;
                padding: 10px 5px;
                
                p{
                    margin: 0!important;
                }

            }
        }
    }
}
.comany-medical {
    .actions{
        button{
            font-size: 15px ;
        }
        i{
            font-size: 18px !important;
        }
    }
   
    
}



///*********Model************
.modal-content{
    width: 900px;
    margin:50px  auto !important;
    min-height: 300px !important;
    position: absolute;
    left: -42%;
   
    .control-field {
       
        float: right;
    }

    .position-absolute{
        display: flex;
    }
    .text_filed{
        padding: 3px;
    }
}
.btn-model-payment{
    
   padding: 10px 90px !important;
}
.modal-content .text_filed{
    height: 55.6px !important;
}
.error_border small.text-danger ,.border-danger small.text-danger{
  
    margin-top: 6px !important;
}